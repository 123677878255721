import { default as _91_46_46_46slug_93l61OJocF99Meta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinrtGDIlWX4HMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbacklcL5IOX61VMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/auth/callback.vue?macro=true";
import { default as bettings1daEvzPnHMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93SxL1D7jD6WMeta } from "/builds/platform/customer-frontends/bk8/pages/bonus/[pageIdentity].vue?macro=true";
import { default as contact4upnMtUT7JMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46client6VRKJTnBwWMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/bk8/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93ofQS8jotoUMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/games/[id].vue?macro=true";
import { default as indexhaSfpBkwwsMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/games/index.vue?macro=true";
import { default as indexk8fu8V4ZKdMeta } from "/builds/platform/customer-frontends/bk8/pages/index.vue?macro=true";
import { default as loyaltyHra8iky9VkMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/loyalty.vue?macro=true";
import { default as mainfIAinlgTiRMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/main.vue?macro=true";
import { default as bonusesIgoms00aR3Meta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documentshkhZQ6nNxRMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/documents.vue?macro=true";
import { default as historyYcYKLTw2oOMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/history.vue?macro=true";
import { default as infoen0B48mmedMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/info.vue?macro=true";
import { default as limitsS5ONqzxwRFMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationszCX2x5YbynMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securitylGWQZkXtzuMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/security.vue?macro=true";
import { default as verificationMQPspHMmUTMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/verification.vue?macro=true";
import { default as walletgrkBuNpYBMMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientNhPULCmoEzMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/profile.client.vue?macro=true";
import { default as providersZiqqPVTEyeMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_939oWTDxTw0RMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionstSN25dRPlcMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientLqlZxZX3IUMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_93ZRtXl3T3zcMeta } from "/builds/platform/customer-frontends/bk8/pages/static/[pageIdentity].vue?macro=true";
import { default as welcome_45packagegwgxkKX0WoMeta } from "/builds/platform/customer-frontends/bk8/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/bk8/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/bk8/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/games/[id].vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/games/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/bk8/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionstSN25dRPlcMeta || {},
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/bk8/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/bk8/pages/static/[pageIdentity].vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/bk8/skeleton/pages/welcome-package.vue")
  }
]